import React from "react";
import { Helmet } from 'react-helmet'

import {SmallHero, Header, Footer} from "../fragments";
import Divider from "../components/Divider";
import Persona from "../components/Persona";

import Guilian from "../images/persona/GuilianGanster.jpg";
import Thomas from "../images/persona/thomas.png";
import Curtis from "../images/persona/curtis.jpg";
import Nina from "../images/persona/nina.jpg";

import Alterway from "../images/clients/alterway.jpg";
import Argosoft from "../images/clients/argosoft.png";
import Google from "../images/clients/google.png";
import Gpg from "../images/clients/gpg.png";
import Taker from "../images/clients/taker.png";
import Jabi from "../images/clients/jabi.png";

import "../styles/agency.scss";

const Agency = () => {
  return (
    <main className="overflow-hidden agency">
      <Helmet>
        <title>Wisoftify - Agence</title>
      </Helmet>
      <Header />
      <SmallHero title="L'agence" />
      <div className="bg-gray-100 pt-8 pb-6" style={{minHeight: "100vh"}}>
        <h2 className="text-center">Notre agence en quelques mots</h2>
        <p className="agency-description">
          Depuis 2019, Wisoftify tiens à coeur de créer <strong>l'outil qui vous correspond</strong>. Notre force réside dans notre capacité à comprendre votre besoin et à le mettre en oeuvre de la façon la plus pertinente possible.
        </p>
        <Divider height={60} className="mb-6" />
        <h2 className="text-center">Notre équipe</h2>
        <div className="flex items-start justify-center flex-wrap px-8 mt-8 mb-10 mx-5" style={{gap: 25}}>
          <Persona className="" img={Guilian} title="Guilian Ganster" poste="Fondateur / Développeur fullstack"/>
          <Persona className="" img={Curtis}  title="Curtis Coureau"  poste="Chef de projet / consultant marketing & communication"/>
          <Persona className="" img={Thomas}  title="Thomas Bastien"  poste="Développeur fullstack"/>
          <Persona className="" img={Nina}    title="Nina Sans"       poste="Designeuse" />
        </div>
        {/*<Divider height={60} />
        <h2 className="text-center">Nos valeurs</h2>*/}
        <Divider height={60} className="mb-6" />
        <h2 className="text-center">Ils nous ont fait confiance</h2>
        <div className="clients flex flex-wrap items-center justify-center my-8 mx-6" style={{gap: 30}}>
          <img src={Alterway} />
          <img src={Argosoft} />
          <img src={Google}   />
          <img src={Gpg}      />
          <img src={Taker}    />
          <img src={Jabi}     />
        </div>
        <Divider height={60} />
      </div>
      <Footer />
    </main>
  )
}

export default Agency;