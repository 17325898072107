import React from "react";

import "../styles/Persona.scss";

const Persona = (props) => {
  const {
    className,
    style,
    img,
    title,
    poste,
  } = props;

  return (
    <div className={`persona flex items-center justify-center flex-col ${className ?? ""}`} style={{...style}}>
      <img src={img} className="" />
      <p className="text-center mt-3 text-2xl font-bold text-gray-800">{title}</p>
      <p className="text-center mt-1 text-xl text-gray-700">{poste}</p>
      <div className="flex items-center justify-center">

      </div>
    </div>
  )
}

export default Persona;